<template>
  <div>
    <v-system-bar app color="secondary" dark fixed height="84">
      <ImpactLogo :background-fill="$vuetify.theme.themes.light.secondary" class="ml-2"/>
      <VSpacer/>
      <AppBarMenu/>
    </v-system-bar>
    <v-main>
      <v-navigation-drawer v-model="isMenuOpen" app clipped width="316">
        <KSelect v-model="clientId"
                 :items="clientList"
                 :loading="clientsListLoading"
                 item-text="name"
                 item-value="id"
                 class="pt-6 pb-2 pl-4 pr-6"
                 offset-y
                 :field="$t('client.clientSelector')"/>
        <TheMainMenu class="pl-4"/>
      </v-navigation-drawer>
      <router-view/>
    </v-main>
  </div>
</template>

<script>
import KSelect from '@/components/crud/fields/KSelect.vue';
import AppBarMenu from '@/components/menu/AppBarMenu.vue';
import TheMainMenu from '@/components/menu/TheMainMenu.vue';
import ImpactLogo from '@/images/ImpactInternationalLogo.vue';
import { index } from '@/modules/client/api/client.js';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Default',
  components: {
    KSelect,
    ImpactLogo,
    AppBarMenu,
    TheMainMenu,
  },
  data() {
    return {
      isMenuOpen: true,
      clientsListLoading: false,
      clientList: [],
    };
  },
  watch: {
    clientList: {
      handler(val) {
        if (!this.clientId && val?.[0]?.id) this.setClientToFirstClient(val);
        if (!val.map(client => client.id).includes(this.clientId) && val?.[0]?.id) this.setClientToFirstClient(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
      getCurrentClientId: 'settings/getCurrentClientId',
    }),
    clientId: {
      get() {
        return this.getCurrentClientId;
      },
      set(value) {
        this.setCurrentClientId(value);
      },
    },
  },
  created() {
    if (!this.isLoggedIn) this.$router.push({ name: 'login', });
    this.getClientsList();
  },
  methods: {
    ...mapMutations('settings', ['setCurrentClientId']),
    async getClientsList() {
      try {
        this.clientsListLoading = true;
        const response = await index();
        this.clientList = response.data.data;
      } catch (e) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t(`errors.clientList`),
        });
      } finally {
        this.clientsListLoading = false;
      }
    },
    setClientToFirstClient(clients) {
      this.clientId = clients[0]?.id
    },
  },
};
</script>
