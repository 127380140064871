<template>
  <v-list tile nav class="pr-0">
    <template v-for="item in items">
      <v-list-group v-if="!!item.items && item.items.length"
                    active-class="activeClass"
                    v-model="item.active"
                    :disabled="item.disabled"
                    :prepend-icon="item.icon"
                    :key="item.title">
        <template #activator>
          <KListTitle v-bind="item"/>
        </template>
        <KListTitle v-bind="subItem" v-for="subItem in item.items" :key="subItem.title"/>
      </v-list-group>

      <KListTitle v-else :key="item.title" v-bind="item"/>
    </template>
  </v-list>
</template>

<script>
import KListTitle from '@/components/menu/KListTitle.vue';

export default {
  name: 'TheMainMenu',
  components: { KListTitle },
  computed: {
    items() {
      return [
        {
          route: { name: 'dashboard' },
          title: this.$t('dashboard.menuTitle'),
          icon: '$home',
        },
        {
          route: { name: 'orders.index' },
          title: this.$t('orders.menuTitle'),
          icon: '$orders',
        },
        {
          route: { name: 'user.index' },
          title: this.$t('user.menuTitle'),
          icon: '$user',
        },
        {
          route: { name: 'tickets.index' },
          title: this.$t('ticket.menuTitle'),
          icon: '$ticket',
        },
        {
          route: { name: 'product.index' },
          title: this.$t('product.menuTitle'),
          icon: '$product',
        },
        {
          route: { name: 'product-category.index' },
          title: this.$t('productCategory.menuTitle'),
          icon: '$productCategories',
        },
        {
          route: { name: 'locationDetails' },
          title: this.$t('locationDetails.menuTitle'),
          icon: '$locationDetails',
          sort: 10,
        },
        {
          route: { name: 'exchange-rate.index' },
          title: this.$t('exchangeRate.menuTitle'),
          icon: '$exchangeRate',
        },
      ];
    },
  },
};
</script>
